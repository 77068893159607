var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"ul"},[(_vm.hasMergerFunction)?_c('div',{staticClass:"li"},[_c('div',{staticClass:"is-flex list-item new-list-item",class:{ active: _vm.isActived },on:{"click":function($event){return _vm.filePanel.getFileList('/DATA')}}},[_c('div',{staticClass:"cover mr-2 is-flex-shrink-0 is-relative"},[_c('div',{staticClass:"icon",on:{"click":_vm.warning,"mouseleave":function($event){_vm.hover = false},"mouseover":function($event){_vm.hover = true}}},[_c('i',{staticClass:"casa casa-28px",class:{
							'casa-storage-merger':
								(!_vm.dorpdown && !_vm.hover) || _vm.mergeStorageList.length === 0,
							'casa-expand':
								_vm.hover && !_vm.dorpdown && _vm.mergeStorageList.length !== 0,
							'casa-expand-down': _vm.dorpdown && _vm.mergeStorageList.length !== 0,
						}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.dorpdown && !_vm.hover && _vm.mergeStorageList.length !== 0),expression:"!dorpdown && !hover && mergeStorageList.length !== 0"}],staticClass:"hint"},[_vm._v(" "+_vm._s(_vm.mergeStorageList.length)+" ")])]),_c('div',{staticClass:"is-flex-grow-1 one-line"},[_vm._v("CasaOS HD")]),(_vm.testMergeMiss > 0)?_c('div',{staticClass:"is-flex is-align-items-center"},[_c('b-icon',{staticClass:"warn",attrs:{"custom-size":"casa-16px","icon":"danger-outline","pack":"casa"}})],1):_vm._e()]),_c('ul',{directives:[{name:"show",rawName:"v-show",value:(_vm.dorpdown && _vm.mergeStorageList.length > 0),expression:"dorpdown && mergeStorageList.length > 0"}]},_vm._l((_vm.mergeStorageList),function(item){return _c('tree-list-item',{key:item.path,attrs:{"isActive":_vm.isActive,"item":item}})}),1)]):_vm._e(),_vm._l((_vm.localStorageList),function(item){return _c('tree-list-item',{key:item.path,attrs:{"isActive":_vm.isActive,"item":item}})}),_vm._l((_vm.networkStorageList),function(item){return _c('tree-list-item',{key:item.path,attrs:{"isActive":_vm.isActive,"item":item,"iconName":"eject"},on:{"rightIconClick":_vm.umountNetwork}})}),_vm._l((_vm.usbStorageList),function(item){return _c('tree-list-item',{key:item.path,attrs:{"isActive":_vm.isActive,"item":item,"iconName":"eject"},on:{"rightIconClick":_vm.umountUsb}})}),_vm._l((_vm.cloudStorageList),function(item){return _c('tree-list-item',{key:item.path,attrs:{"iconType":item.icon_type,"isActive":_vm.isActive,"item":item,"iconName":"eject"},on:{"rightIconClick":_vm.umountCloud}})})],2),_c('b-loading',{attrs:{"is-full-page":false},model:{value:(_vm.isLoading),callback:function ($$v) {_vm.isLoading=$$v},expression:"isLoading"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }